
/**
 * @name: 抖音团购-团购管理
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 抖音团购-团购管理
 * @update: 2023-09-07 09:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import { productGetListApi} from "@/apis/product/info";
import {
  groupPurchaseManageApi,
  groupPurchaseManageCreateApi, groupPurchaseManageDetailApi, groupPurchaseManageModifyApi,
  groupPurchaseManageQueryApi,
  groupPurchaseManageUpdateStatusApi, writeOffRecordQueryApi
} from "@/apis/tiktok";
import {deepCopy} from "@/utils/common";
import {productUnitQueryApi} from "@/apis/product/unit";

@Component({})
export default class tiktokManage extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 表格数据
  tableDataWrite: any = []
  // 表格总数
  tableTotalWrite = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  disabledList: any = []
  goodsLoading: boolean = false
  // 查询参数
  queryParamVerification: any = {
    page: 1,
    limit: 10
  }
  // 新增修改弹窗
  addDialog: boolean = false
  // 新增修改弹出标题
  addDialogTitle: string = ''
  // 核验弹窗
  verificationDialog: boolean = false
  detailDialog: boolean = false
  detailData: any = {}
  // 表单参数
  modelForm: Partial<any> = {
    groupPurchaseProductDetailDtoList: [],
    productId: '',
    groupPurchaseName: '',
    groupPurchaseId: '',
    groupPurchasePrice: '',
    productIdUnit: '',
    id:null

  }
  //商品列表
  productList: any = []
  productUnitList: any = []
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    addTitle: '新增供应商',
    editTitle: '编辑供应商',
    dialogWidth: '800px',
    labelWidth: '180px',
    menu: true,
    editBtn: true,
    delBtn: false,
    column: [
      {
        label: "团购",
        prop: "groupPurchaseKeywords",
        align: "center",
        search: true,
        hide: true,
        placeholder: '输入团购ID/名称搜索',
        viewHide: true
      },
      {
        label: "团购ID",
        prop: "groupPurchaseId",
        align: "center",
        slot: true
      },
      {
        label: "团购名称",
        prop: "groupPurchaseName",
        align: "center",
      },
      // {
      //   label: "团购价(元)",
      //   prop: "groupPurchasePrice",
      //   align: "center",
      // },
      {
        label: "已核验数量",
        prop: "verificationNumber",
        align: "center",
        slot: true,
        viewHide: true
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        search: true,
        type: "switch",//状态:1=启用 2=禁用
        value: 1,
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 24,
        viewHide: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        search: true,
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        viewHide: true
      },
    ]
  }
  crudOptionVerification: ICrudOption = {
    searchBox: false,
    menu: false,
    editBtn: true,
    delBtn: false,
    column: [
      {
        label: "序号",
        type: "index",
        width: 50,
        align: "center"
      },
      {
        label: "券码ID",
        prop: "originCode",
        align: "center",
      },
      {
        label: "关联销售订单编号",
        prop: "saleOrderSn",
        align: "center",
      },
      {
        label: "金额",
        prop: "groupPurchasePrice",
        align: "center",
      },
      {
        label: "核验人",
        prop: "verifiedName",
        align: "center",
      },
      {
        label: "核验时间",
        prop: "addTime",
        align: "center",
      },
    ]
  }
  rules = {
    groupPurchaseId: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error('请输入团购ID'));
          } else if (!/^\d{1,20}$/.test(value)) {
            callback(new Error('请输入数字，最大长度20'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    groupPurchaseName: [
      {required: true, message: '请输入团购名称', trigger: 'blur'},
      {min: 1, max: 50, message: '长度最大50个字符', trigger: 'blur'}
    ],
    groupPurchasePrice: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error('请输入团购价'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    price: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          console.log(value)
          if (!value) {
            callback(new Error('请输入单价'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    quantity: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          console.log(value)
          if (!value) {
            callback(new Error('请输入数量'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ]
  }

  async openAdd() {
    this.modelForm.productId = ''
    this.modelForm.productIdUnit = ''
    this.modelForm.groupPurchaseId = ''
    this.modelForm.groupPurchaseName = ''
    this.modelForm.groupPurchasePrice = ''
    this.modelForm.groupPurchaseProductDetailDtoList = []
    this.modelForm.id = null
    this.disabledList = []
    this.addDialogTitle = '新增团购'
    // this.modelForm = {}
    this.addDialog = true
    this.getProductGetList()
  }

  openEdit(row: any) {
    this.disabledList = []
    this.addDialogTitle = '编辑团购'
    this.modelForm = {}
    this.addDialog = true
    this.getProductGetList()
    groupPurchaseManageDetailApi(row.id).then(e => {
      if (e) {
        this.modelForm = e
        this.modelForm.groupPurchaseProductDetailDtoList = e.groupPurchaseProductDetailVoList
        for (let i = 0; i < this.modelForm.groupPurchaseProductDetailDtoList.length; i++) {
          this.disabledList.push(this.modelForm.groupPurchaseProductDetailDtoList[i].productId)
        }
      }
    })
  }

  openVerification(row: any) {
    this.verificationDialog = true
    this.queryParamVerification.groupPurchaseId = row.groupPurchaseId
    this.queryParamVerification.page = 1
    this.queryParamVerification.limit = 10
    this.getListVerification()
  }

  getListVerification(){
    writeOffRecordQueryApi(this.queryParamVerification).then(e => {
      this.tableDataWrite = e.list;
      this.tableTotalWrite = e.total;
    })
  }

  /**
   * 修改状态提交
   * @param row
   */
  handleStatus(row: any, val: number) {
    groupPurchaseManageUpdateStatusApi({id: row.id, status: val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  /**
   * 获取列表数据
   */
  getList() {
    const form: any = deepCopy(this.queryParam);
    if (this.queryParam.addTime && this.queryParam.addTime.length) {
      form.addTimeStart = this.queryParam.addTime[0]
      form.addTimeEnd = this.queryParam.addTime[1]
    } else {
      form.addTimeStart = ""
      form.addTimeEnd = ""
    }
    delete form.addTime
    groupPurchaseManageQueryApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  /**
   * 获取商品下拉列表
   */
  getProductGetList() {
    productGetListApi(1).then(e => {
      this.productList = e;
    })
    // productUnitQueryApi().then(e => {
    //   this.productUnitList = e
    // })
  }

  /**
   * 商品删除
   */
  unitRemove(index: number, row: any) {
    this.modelForm.groupPurchaseProductDetailDtoList.splice(index, 1)
    this.disabledList.splice(this.disabledList.indexOf(row.productId), 1)
  }

  changCoupon(e: any) {
    if (this.modelForm.productIdUnit){
      this.modelForm.productIdUnit = ''
    }
    if (e){
      groupPurchaseManageApi({productId:e}).then(e=>{
        this.productUnitList = e
      })
    }else {
      this.productUnitList = []
    }
  }

  quantityChang(row: any) {
    let num = (row.price || 0) * (row.quantity || 0)
    row.productAmount = Math.trunc((num * 100)) / 100
  }

  openDetail(row: any,) {

    groupPurchaseManageDetailApi(row.id as string).then(e => {
      if (e) {
        this.detailData = e
        console.log(this.detailData)
        this.detailDialog = true
      }
    })
  }


  /**
   * 添加商品
   */
  addProduct() {
    if (!this.modelForm.productId || !this.modelForm.productIdUnit) {
      return this.$message.error('请选择商品及单位！')
    }
    this.disabledList.push(this.modelForm.productId)
    let data = {} as any
    for (let i = 0; i < this.productList.length; i++) {
      let item = this.productList[i]
      if (this.modelForm.productId == item.id) {
        data.productId = item.id
        data.productName = item.productName
        data.productAmount = 0
        data.saleUnit = this.modelForm.productIdUnit
        data.price = 0
        data.quantity = 0
      }
    }
    this.modelForm.groupPurchaseProductDetailDtoList.push(data)
    this.modelForm.productId = ''
    this.modelForm.productIdUnit = ''
    this.productUnitList = []
  }

  rowSave() {
    // @ts-ignore
    this.$refs['form'].validate((valid) => {
      if (valid) {
        if (this.modelForm.groupPurchaseProductDetailDtoList.length == 0) {
          return this.$message.error('请添加团购商品明细！')
        }

        if (this.modelForm.id) {
          groupPurchaseManageModifyApi(this.modelForm).then((e: any) => {
            console.log(e)
            if (e) {
              this.$message.success('操作成功')
              this.addDialog = false
              this.getList()
            }
          })
        } else {
          groupPurchaseManageCreateApi(this.modelForm).then((e: any) => {
            console.log(e)
            if (e) {
              this.$message.success('操作成功')
              this.addDialog = false
              this.getList()
            }
          })
        }


      }
    })
  }

  created() {

    this.getList()
  }
}
