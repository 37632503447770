
import {get, postJ} from "@/request"
import {ISupplier} from "@/apis/purchase/supplier/types";
import {IDistributeRecord} from "@/apis/storage/gateDist/types";
export const groupPurchaseManageQueryApi = (params: any) => get("/admin/groupPurchaseManage/queryByPage", params)

export const groupPurchaseManageCreateApi = (params: any) => postJ("/admin/groupPurchaseManage/create", params)

export const groupPurchaseManageUpdateStatusApi = (data: any) => postJ("/admin/groupPurchaseManage/updateStatus" ,data)

export const groupPurchaseManageDetailApi = (id: string) => get(`/admin/groupPurchaseManage/detail/${id}`)

export const groupPurchaseManageModifyApi = (params: any) => postJ("/admin/groupPurchaseManage/modify", params)

export const writeOffRecordQueryApi = (params: any) => get("/admin/writeOffRecord/queryByPage", params)

export const writeOffRecordExportApi = (params: any) => get("/admin/writeOffRecord/writeOffRecordExport", params,"blob")

export const groupPurchaseManageApi = (params: any) => get("/admin/groupPurchaseManage/queryProductUnitList", params)
